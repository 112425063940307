// Required imports
import React from 'react'
import styled from 'styled-components'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

// Custom file imports
import MyLink from '../components/my-link.js'
import Info from '../components/info.js'
import { SlothLogo } from '../components/logos.js'

/// //////////////////////////////////////////////////////////
// G L O B A L S
/// //////////////////////////////////////////////////////////

const ROW_CLASS = 'justify-content-md-center margin-top md'

const Center = styled.span`
  text-align: center;
`
const EmailLink = ({ children }) =>
  <MyLink link tab href='mailto:support@novaslothgames.com'>
    {children}
  </MyLink>

/// //////////////////////////////////////////////////////////
// C O M P O N E N T   D E F I N I T I O N
/// //////////////////////////////////////////////////////////

export default function Support () {
  return (
    <Container fluid>
      <Row className={ROW_CLASS}>
        <Info>
          Questions, comments, or bugs?
          Email us at{' '}
          <EmailLink>
            support@novaslothgames.com
          </EmailLink>
        </Info>
        <Center>
          <EmailLink>
            <SlothLogo size='sm' />
          </EmailLink>
        </Center>
      </Row>
    </Container>
  )
}