// Required imports
import styled from 'styled-components'

/// //////////////////////////////////////////////////////////
// C O M P O N E N T   D E F I N I T I O N
/// //////////////////////////////////////////////////////////

const Info = styled.p`
  margin-top: 10px;
  font-size: 1.1rem;
  text-align: center;
  color: grey;
`

export default Info